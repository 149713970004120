import React from "react"
import CommercialContact from '../components/sections/commercial-contact'
import Contact from '../components/sections/contact'
import Process from '../components/sections/process'
import Showcase from '../components/sections/showcase'
import Partners from '../components/sections/partners'
import Slider from '../components/sections/slider'
import Solutions from '../components/sections/solutions'
import SEO from '../components/seo'
import WhatsAppAction from '../components/whatsapp'

export default () => {
  return <React.Fragment>
    <SEO />
    <WhatsAppAction phone='573015013578' text='Hola, quisiera saber más acerca de las soluciones que ofrecen.' />
    <Slider />
    <Solutions />
    <Process />
    <CommercialContact />
    <Showcase />
    <Partners />
    <Contact />
  </React.Fragment>
}
