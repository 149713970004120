import { Button, Col, Form, Row } from "react-bootstrap"

import { Box } from "theme-ui"

import React, { useEffect, useState, useCallback } from "react"
import axios from "axios"
import MessageModal from "../MessageModal"

const Contact = () => {
  const [form, setForm] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [modalContent, setModalContent] = useState(["", <></>])
  const [isOpenModal, setOpenModal] = useState(false)

  const setField = useCallback(
    (field, value) => {
      setForm({
        ...form,
        [field]: value,
      })
    },
    [form]
  )

  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        axios
          .get(process.env.GATSBY_API_URL + "/auth/landing/get-token", {
            withCredentials: false,
          })
          .then(res => {
            setField("csrfmiddlewaretoken", res.data.token)
          })
      } catch (e) {
        // do nothing
      }
    }
    getCsrfToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const handleOpenModal = () => {
    setOpenModal(!isOpenModal)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData()
    Object.keys(form).forEach(key => {
      // @ts-ignore
      formData.append(key, form[key])
    })
    await axios({
      method: "post",
      url: process.env.GATSBY_API_URL + "/auth/landing/contact",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(() =>
        setModalContent([
          "¡Gracias por contactarnos!",
          <p>Hemos recibido tus datos.</p>,
        ])
      )
      .catch(e =>
        setModalContent([
          "Ha ocurrido un error",
          <p>
            Ocurrió un error inesperado de parte del servidor, inténtelo
            nuevamente en unos instantes.
          </p>,
        ])
      )
      .finally(() => {
        handleOpenModal(true)
        setIsLoading(false)
      })
  }
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#f5f6f6",
      }}
      id="contacto"
    >
      <Box
        sx={{
          pt: [4, 5],
          pb: [4, 5],
          px: [3, 1],
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
        <Row sx={{ margin: 0 }}>
          <Col md={4}>
            <Box
              as="h1"
              sx={{
                variant: "main.titleBorder",
                textAlign: "left",
                paddingRight: 5,
              }}
              className="title-top-left-border"
            >
              CONTÁCTENOS
            </Box>
            <Box
              as="h4"
              sx={{
                textAlign: "left",
                marginTop: 40,
              }}
            >
              BOGOTÁ
              <br />
              TEL. (571) 7441399
              <br />
              CARRERA 47A # 101A - 58
            </Box>
            <Box
              as="h4"
              sx={{
                textAlign: "left",
                marginTop: 20,
              }}
            >
              MEDELLÍN
              <br />
              CALLE 15 SUR # 120 - 46 OF. 101B
            </Box>
          </Col>
          <Col
            sx={{
              paddingTop: 25,
            }}
            md={{ span: 7, offset: 1 }}
          >
            <Form className="form" onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Nombres y Apellidos"
                  onChange={e => setField("names", e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCompany">
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Empresa"
                  onChange={e => setField("company", e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Control
                  size="lg"
                  type="email"
                  placeholder="Correo Electrónico"
                  onChange={e => setField("email", e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Control
                  as="textarea"
                  size="lg"
                  type="text"
                  placeholder="Mensaje"
                  onChange={e => setField("message", e.target.value)}
                  required
                  style={{ minHeight: 100 }}
                />
              </Form.Group>
              <Button
                size="lg"
                variant="primary"
                type="submit"
                className="float-right"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>ENVIAR</>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Box>
      <MessageModal
        show={isOpenModal}
        onClose={handleOpenModal}
        title={modalContent[0]}
        noGreenDot
        text={modalContent[1]}
      />
    </Box>
  )
}

export default Contact
