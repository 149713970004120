import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { Col, Row } from 'react-bootstrap'
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import HomeCard from '../../components/homecard'

export default () => {
  return <Box
    sx={{
      pt: [4, 5],
      pb: [4, 5],
      px: [3, 1],
      maxWidth: 1500,
      margin: '0 auto'
    }}
    id='soluciones'
  >
    <StaticQuery
      query={graphql`
        query {
          colaboracionIcon: file(relativePath: { eq: "icon_colaboracion.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          controlRoomIcon: file(relativePath: { eq: "icon_control_room.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desarrolloSoftwareIcon: file(relativePath: { eq: "icon_desarrollo_software.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          digitalSignageIcon: file(relativePath: { eq: "icon_digital_signage.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          infraestructuraITIcon: file(relativePath: { eq: "icon_infraestructura_it.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          retailIntelligenceIcon: file(relativePath: { eq: "icon_retail_intelligence.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          seguridadFisicaIcon: file(relativePath: { eq: "icon_seguridad_fisica.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          serviciosOperacionMantenimientoIcon: file(relativePath: { eq: "icon_servicios_operacion_mantenimiento.png" }) {
            childImageSharp {
              fluid(maxHeight: 100, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <React.Fragment>
            <Box
              as="h1"
              sx={{
                variant: 'main.titleBorder'
              }}
              className='title-top-border'>
              SOLUCIONES
            </Box>
            <Row
              sx={{ margin: 0 }}
            >
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.digitalSignageIcon.childImageSharp.fluid}
                  title='DIGITAL SIGNAGE'
                  description='Entregue su mensaje de forma impactante, eficaz y conectada al mundo.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.controlRoomIcon.childImageSharp.fluid}
                  title='CONTROL ROOM'
                  description='Visualice de forma centralizada su información en tiempo real y tome decisiones que impacten positivamente sus procesos.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.colaboracionIcon.childImageSharp.fluid}
                  title='COLABORACIÓN'
                  description='Conecte su empresa y permita que la tecnología haga más eficientes sus sesiones de trabajo.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.seguridadFisicaIcon.childImageSharp.fluid}
                  title='SEGURIDAD FÍSICA'
                  description='Monitoree sus activos y operaciones más importantes.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.retailIntelligenceIcon.childImageSharp.fluid}
                  title='RETAIL INTELLIGENCE'
                  description='Entienda quién es su cliente, cómo se mueve, cómo piensa y anticipe comportamientos que generen mayores ventas.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.infraestructuraITIcon.childImageSharp.fluid}
                  title='INFRAESTRUCTURA IT'
                  description='Implemente toda la base tecnológica para que soporte su estrategia de negocio.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.serviciosOperacionMantenimientoIcon.childImageSharp.fluid}
                  title='SERVICIOS DE OPERACIÓN Y MANTENIMIENTO'
                  description='Delegue la operación de su infraestructura tecnológica, asegurando su disponibilidad y eficiencia.' />
              </Col>
              <Col md={4} lg={3} sx={{ my: [10, 15] }}>
                <HomeCard
                  icon={data.desarrolloSoftwareIcon.childImageSharp.fluid}
                  title='DESARROLLO DE SOFTWARE'
                  description='Automatice y optimice sus procesos, organizando datos que permitan analizar la información y generar ventajas competitivas.' />
              </Col>
            </Row>
          </React.Fragment>
        )
      }}
    />
  </Box>
}