import PropTypes from "prop-types"
import Img from "gatsby-image"
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import styles from "./homecard.module.css"

const HomeCard = ({ icon, title, caseTitle, caseImage, description }) => {
    return (
        <Box
            className={caseTitle ? styles.showcaseCard : styles.card}>
            {caseTitle && (
                <h3
                    sx={{
                        fontSize: [20, 25]
                    }}
                >
                    {caseTitle}
                </h3>
            )}
            {icon && (
                <Img
                    fluid={icon}
                    alt={title}
                    imgStyle={{ objectFit: 'contain' }}
                    style={{ maxHeight: 100, maxWidth: '100%' }}
                />
            )}
            {caseImage && (
                <Img
                    fluid={caseImage}
                    alt={title}
                    imgStyle={{ objectFit: 'contain' }}
                    style={{ maxHeight: 300, maxWidth: '100%' }}
                />
            )}
            {title && (
                <h3
                    sx={{
                        fontSize: [20, 25]
                    }}
                >
                    {title}
                </h3>
            )}
            {description && (
                <p
                    sx={{
                        fontSize: [15, 16]
                    }}>
                    {description}
                </p>
            )}
        </Box>
    )
}

export default HomeCard
HomeCard.propTypes = {
    icon: PropTypes.object,
    caseImage: PropTypes.object,
    title: PropTypes.string,
    caseTitle: PropTypes.string,
    description: PropTypes.string,
}