import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from "prop-types"
/** @jsx jsx */
import { jsx } from 'theme-ui'
import styles from "./whatsapp.module.css"
import useDeviceDetect from "../utils/useDeviceDetect";

const WhatsAppAction = ({ phone, text }) => {
    const { isMobile } = useDeviceDetect();
    const apiUrl = isMobile ? `https://wa.me/${phone}?text=${text}` : `https://web.whatsapp.com/send?phone=${phone}&text=${text}`;
    return (
        <a
            href={apiUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.waFloat}
            sx={{
                bottom: [2, 4],
                right: [2, 4],
            }}
        >
            <FontAwesomeIcon
                icon={faWhatsapp}
                className={styles.waIcon} />
        </a>
    )
}

export default WhatsAppAction
WhatsAppAction.propTypes = {
    phone: PropTypes.string,
    text: PropTypes.string,
}