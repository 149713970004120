import { graphql, StaticQuery } from "gatsby"
import { useEffect, useState } from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import whiteLogo from '../../../static/images/logos/icomm_logo_white.png'
import styles from "./process.module.css"

export default () => {
  const [smHeight, setSmHeight] = useState('90vh');
  const [mdHeight, setMdHeight] = useState('90vh');
  const browserWindow = typeof window !== `undefined` ? window : false;
  useEffect(() => {
    setSmHeight(window.innerHeight - 91);
    setMdHeight(window.innerHeight - 105);
  }, [browserWindow])
  return <Box
    sx={{
      width: '100%',
      height: [smHeight, mdHeight],
      minHeight: [400, 800]
    }}
    id='proceso'
  >
    <StaticQuery
      query={graphql`
        query {
          process_bg: file(relativePath: { eq: "process_bg.png" }) {
            childImageSharp {
              fluid(maxWidth: 1900, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          process: file(relativePath: { eq: "process.png" }) {
            childImageSharp {
              fluid(maxWidth: 1705, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <BackgroundImage
            Tag="section"
            className={styles.processBg}
            fluid={data.process_bg.childImageSharp.fluid}
            sx={{
              pt: [4, 5],
              pb: [4, 5],
              px: [3, 1],
            }}
          >
            <Box
              as="h1"
              sx={{
                variant: 'main.titleBorder',
              }}
              className='title-top-border'>
              PROCESO
            </Box>
            <img
              sx={{
                maxHeight: [65, 85],
                marginTop: [30, 40]
              }}
              src={whiteLogo}
              alt="iComm Solutions"
            />
            <Img
              sx={{
                margin: ['100px auto', '65px auto'],
                maxWidth: 1200
              }}
              fluid={data.process.childImageSharp.fluid}
              alt="Proceso"
            />
          </BackgroundImage>
        )
      }}
    />
  </Box>
}