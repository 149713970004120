import { graphql, StaticQuery } from "gatsby"
import { useEffect, useState } from "react"
import BackgroundImage from "gatsby-background-image"
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import styles from "./slider.module.css"

export default () => {
  const [smHeight, setSmHeight] = useState('90vh');
  const [mdHeight, setMdHeight] = useState('90vh');
  const browserWindow = typeof window !== `undefined` ? window : false;
  useEffect(() => {
    setSmHeight(window.innerHeight - 91);
    setMdHeight(window.innerHeight - 105);
  }, [browserWindow])
  return <Box
    sx={{
      maxHeight: [smHeight, mdHeight]
    }}
    id='home'
  >
    <StaticQuery
      query={graphql`
        query {
          banner1: file(relativePath: { eq: "banner1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <BackgroundImage
            Tag="section"
            className={styles.sliderBg}
            fluid={data.banner1.childImageSharp.fluid}
          >
            <div
              sx={{
                height: [smHeight, mdHeight],
                flexDirection: 'column'
              }}
              className={styles.sliderText}
            >
              <p
                sx={{
                  marginBottom: ['10vh'],
                  fontSize: [27, 50, 65]
                }}
                className={styles.topWrapper}
              >
                INTEGRADORES DE<br />SOLUCIONES TECNOLÓGICAS
              </p>
              <div
                className={styles.container}
                sx={{
                  bottom: [5, 5],
                }}
              >
                <div className={styles.chevron}></div>
                <div className={styles.chevron}></div>
                <div className={styles.chevron}></div>
              </div>
            </div>
          </BackgroundImage>
        )
      }}
    />
  </Box>
}