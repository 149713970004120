import { graphql, StaticQuery } from "gatsby"
import { Col, Row } from 'react-bootstrap'
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import HomeCard from '../homecard'

export default () => {
  return <Box
    sx={{
      width: '100%',
    }}
    id='exito'>
    <StaticQuery
      query={graphql`
        query {
          adidas: file(relativePath: { eq: "ADIDAS.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          car: file(relativePath: { eq: "CAR.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          marval: file(relativePath: { eq: "MARVAL.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          dipol: file(relativePath: { eq: "DIPOL.png" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          fontanar: file(relativePath: { eq: "FONTANAR.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bavaria: file(relativePath: { eq: "BAVARIA.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          intel: file(relativePath: { eq: "INTEL.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          caso1: file(relativePath: { eq: "caso1.png" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          laserrezuela: file(relativePath: { eq: "LASERREZUELA.png" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sabanapark: file(relativePath: { eq: "SABANAPARK.jpg" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sicca: file(relativePath: { eq: "SICCA.png" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          toolitcare: file(relativePath: { eq: "TOOLITCARE.png" }) {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Box
            sx={{
              pt: [4, 5],
              pb: [4, 5],
              px: [3, 1],
              maxWidth: 1300,
              margin: '0 auto'
            }}
          >
            <Box
              as="h1"
              sx={{
                variant: 'main.titleBorder'
              }}
              className='title-top-border'>
              CASOS DE ÉXITO
            </Box>
            <Row
              sx={{ margin: 0 }}
            >
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.adidas.childImageSharp.fluid}
                  caseTitle='ADIDAS' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.car.childImageSharp.fluid}
                  caseTitle='CAR' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.marval.childImageSharp.fluid}
                  caseTitle='MARVAL' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.dipol.childImageSharp.fluid}
                  caseTitle='DIPOL' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.fontanar.childImageSharp.fluid}
                  caseTitle='FONTANAR' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.bavaria.childImageSharp.fluid}
                  caseTitle='BAVARIA' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.caso1.childImageSharp.fluid}
                  caseTitle='CERREJÓN' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.intel.childImageSharp.fluid}
                  caseTitle='INTEL EXPERIENCE DAY' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.laserrezuela.childImageSharp.fluid}
                  caseTitle='LA SERREZUELA' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.toolitcare.childImageSharp.fluid}
                  caseTitle='TOOLIT CARE' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.sicca.childImageSharp.fluid}
                  caseTitle='SICCA' />
              </Col>
              <Col lg={4} sx={{ my: [10, 15] }}>
                <HomeCard
                  caseImage={data.sabanapark.childImageSharp.fluid}
                  caseTitle='SABANA PARK' />
              </Col>
            </Row>
          </Box>
        )
      }}
    />
  </Box >
}