import React from "react"
import { Modal, Button } from "react-bootstrap"

const MessageModal = props => {
  const handleClose = () => {
    props.onClose()
  }

  return (
    <Modal {...props} show={props.show}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.text}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MessageModal
