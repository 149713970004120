import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Col, Row } from 'react-bootstrap'
/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import styles from "./partners.module.css"

export default () => {
  return <Box
    sx={{
      width: '100%',
    }}
    id='clientes'>
    <StaticQuery
      query={graphql`
        query {
          adidasLogo: file(relativePath: { eq: "ADIDAS.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bavariaLogo: file(relativePath: { eq: "BAVARIA.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bolivarianoLogo: file(relativePath: { eq: "BOLIVARIANO.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          carLogo: file(relativePath: { eq: "CAR.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cerrejonLogo: file(relativePath: { eq: "CERREJON.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          clarionHotelLogo: file(relativePath: { eq: "CLARION HOTEL.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          dentixLogo: file(relativePath: { eq: "DENTIX.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          fontanarLogo: file(relativePath: { eq: "FONTANAR.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          icbfLogo: file(relativePath: { eq: "ICBF.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          laSerrezuelaLogo: file(relativePath: { eq: "LA SERREZUELA.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          lenovoLogo: file(relativePath: { eq: "LENOVO.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          marvalLogo: file(relativePath: { eq: "MARVAL.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ramaJudicialLogo: file(relativePath: { eq: "RAMA JUDICIAL.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          transmilenioLogo: file(relativePath: { eq: "TRANSMILENIO.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          uneLogo: file(relativePath: { eq: "UNE.png" }) {
            childImageSharp {
              fluid(maxHeight: 50, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Box
            sx={{
              pt: 0,
              pb: [4, 5],
              px: [3, 1],
              maxWidth: 1000,
              margin: '0 auto'
            }}
          >
            <Box
              as="h1"
              sx={{
                variant: 'main.titleBorder'
              }}
              className='title-top-border'>
              CLIENTES
            </Box>
            <Row
              sx={{ margin: 0 }}
              className={styles.partnersWrapper}
            >
              <Col>
                <Img
                  fluid={data.bavariaLogo.childImageSharp.fluid}
                  alt='Bavaria'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.icbfLogo.childImageSharp.fluid}
                  alt='ICBF'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.transmilenioLogo.childImageSharp.fluid}
                  alt='Transmilenio'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.uneLogo.childImageSharp.fluid}
                  alt='UNE'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.adidasLogo.childImageSharp.fluid}
                  alt='Adidas'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.marvalLogo.childImageSharp.fluid}
                  alt='MARVAL'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.ramaJudicialLogo.childImageSharp.fluid}
                  alt='Rama Judicial'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.clarionHotelLogo.childImageSharp.fluid}
                  alt='Clarion Hotel'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.carLogo.childImageSharp.fluid}
                  alt='CAR'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 65, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.fontanarLogo.childImageSharp.fluid}
                  alt='Fontanar'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 100, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.dentixLogo.childImageSharp.fluid}
                  alt='Dentix'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 120, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.lenovoLogo.childImageSharp.fluid}
                  alt='Lenovo'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 110, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.bolivarianoLogo.childImageSharp.fluid}
                  alt='Bolivariano'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 120, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.laSerrezuelaLogo.childImageSharp.fluid}
                  alt='La Serrezuela'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 120, margin: '25px 6px' }}
                />
              </Col>
              <Col>
                <Img
                  fluid={data.cerrejonLogo.childImageSharp.fluid}
                  alt='Cerrejón'
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ maxHeight: 50, width: 120, margin: '25px 6px' }}
                />
              </Col>
            </Row>
          </Box>
        )
      }}
    />
  </Box>
}